import logo777 from "../../assets/logo777.png";
import favbet from "../../assets/favbet.png";
import supergra from "../../assets/supergra.png";
import info from "../../assets/info.png";
import stars from "../../assets/stars.png";
import prize from "../../assets/prize.png";
import hot from "../../assets/hpt.png";
import ggbet from "../../assets/ggbet.png";
import code from "../../assets/code.png";
import slotscity from "../../assets/slotscity.png";
import first from "../../assets/first.png";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  if (sub9 !== null && sub9) {
    localStorage.setItem("sub9", sub9);
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub3 !== null) {
      n.variables["sub3"] = sub3;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (sub8 !== null) {
      n.variables["sub8"] = sub8;
    }
    if (sub9 !== null) {
      n.variables["sub9"] = sub9;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://s931tas.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "super") {
        onWebsiteSuperBtnClick();
      } else if (offer === "favbet") {
        onWebsiteFavBtnClick();
      } else if (offer === "777") {
        onWebsite777BtnClick();
      } else if (offer === "ggbet") {
        onWebsiteGGbetBtnClick();
      } else if (offer === "slotscity") {
        onWebsiteSlotscityBtnClick();
      } else if (offer === "first") {
        onWebsiteFirstBtnClick();
      }
    }, 3000);
  };

  function onWebsiteFavBtnClick() {
    window.location.href =
      `https://indoorway.top/R8T7yyYQ?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSuperBtnClick() {
    window.location.href =
      `https://indoorway.top/Z5jZhkwd?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsite777BtnClick() {
    window.location.href =
      `https://indoorway.top/q9K85n2G?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteGGbetBtnClick() {
    window.location.href =
      `https://indoorway.top/mtzSBfmM?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSlotscityBtnClick() {
    window.location.href =
      `https://ringmaster.top/NKxxfdfR?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteFirstBtnClick() {
    window.location.href =
      `https://ringmaster.top/RZTmfhcw?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  return (
    <main className="main">
      <div className="container">
        <p className="main__title">Найкращі казино</p>
        <div className="main__thumb">
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={first} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      First Casino
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    <p className="main__one--textTop">ФРІСПІНИ</p>
                    <p className="main__one--textBottom">400</p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">гривень</p>
                    <p className="main__two--textBottom">100000</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новий бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjI3ODAz&domain=winvibe_bot",
                    "first"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div>{" "}
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={slotscity} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">SC</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    <p className="main__one--textTop">ФРІСПІНИ</p>
                    <p className="main__one--textBottom">100</p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    {/* <p className="main__two--textTop">БЕЗ</p> */}
                    <p
                      className="main__two--textBottom"
                      style={{ fontSize: "19px" }}
                    >
                      БЕЗ ВІДІГРАШУ
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Промокод: CLUBWIN</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjI3ODA2&domain=winvibe_bot",
                    "slotscity"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div>
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={supergra} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Super Gra</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textTop">ФРІСПІНІВ</p>
                    <p className="main__one--textBottom">100</p>
                  </div> */}
          {/* <div className="main__box--two">
                    <p className="main__two--textTop">БЕЗ</p>
                    <p className="main__two--textBottom">відіграшу</p>
                  </div> */}
          {/* </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjUw&domain=free_spins_ua_bot",
                    "super"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--two" style={{ width: "100%" }}>
                    <p className="main__two--textTop">ФРІСПІНІВ</p>
                    <p className="main__two--textBottom">100</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjQ3&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={code} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    <p className="main__one--textTop">ФРІСПІНІВ</p>
                    <p className="main__one--textBottom">25</p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">промокод</p>
                    <p className="main__two--textBottom">fav25fs</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA4NDAy&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={ggbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">GGbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    <p className="main__one--textTop">ФРІСПІНІВ</p>
                    <p className="main__one--textBottom">50</p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">гривень</p>
                    <p className="main__two--textBottom">12000</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA5MDY1&domain=free_spins_ua_bot",
                    "ggbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={logo777} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">777</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textTop">БОНУС</p>
                    <p className="main__one--textBottom">200 грн</p>
                  </div>
                   <div className="main__box--two">
                    <p className="main__two--textTop">БОНУС</p>
                    <p className="main__two--textBottom">
                      10<span style={{ letterSpacing: "0.1em" }}>0</span>00
                      <span style={{ letterSpacing: "0.1em" }}>0</span>₴
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjUz&domain=free_spins_ua_bot",
                    "777"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={28} />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  );
};
